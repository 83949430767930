<template>
  <div>
    <!-- <MainSearch @updateSearch="updateSearchParams($event)" /> Too many issues -->
    <div class="d-flex align-center mt-6 mb-3">
      <v-btn :to="{name: 'Market'}" class="mx-6" color="primary">Go Back</v-btn>
      <h3 class="text-h3">Your search results...</h3>
    </div>
    <!-- Virtual Scroller for Rows -->
    <v-container>
      <VirtualGrid
          v-if="loaded"
          :items="items"
          :loader="reachedMax ? null : LoaderComponent"
          :updateFunction="pullDataWithDelay"
          :updateTriggerMargin="reachedMax ? -50 : 50"
      />
    </v-container>
    <!-- End of results component -->
    <div v-show="reachedMax" class="d-flex justify-center align-center my-16">
      <h1 class="my-6 text-center">You have reached the bottom!</h1>
    </div>
  </div>
</template>

<script>
import MainSearch from "@/components/MainSearch.vue";
import PartCard from "@/components/PartCard.vue";
import Loader from "@/components/Loader.vue";
import VirtualGrid from "vue-virtual-grid";
import {mapActions} from "vuex";

export default {
  components: {MainSearch, VirtualGrid},
  emits: ["updateSearch"],
  metaInfo: {
    title: "Search Results",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
  data: () => ({
    items: [],
    loaded: false,
    LoaderComponent: Loader,
    start: 0,
    limit: 12,
    reachedMax: false,
    searchInfo: {},
  }),
  mounted() {
    this.searchInfo = this.$route.query;
    this.initializeList();
  },
  methods: {
    ...mapActions('data', ["postData"]),
    initializeList() {
      this.fetchItems()
          .catch((error) => {
            if (error) {
              console.error("Failed to load initial data", error);
            }
          })
          .then(() => {
            this.loaded = true;
          });
    },
    generateItem(itemData) {
      return {
        id: this.items.length.toString(),
        injected: itemData,
        height: 480,
        columnSpan: 1,
        newRow: false,
        renderComponent: PartCard,
      };
    },
    async fetchItems() {
      if (this.reachedMax) {
        return;
      }
      let info = this.searchInfo;
      info["start"] = this.start;
      info["limit"] = this.limit;
      let response = await this.postData({url: "fetchSearch", data: info});
      if (response["error"] !== "none" || response["error"] === undefined) {
        //alert('An error has occured')
      }
      if (response["reachedMax"] === true) {
        this.reachedMax = true;
      } else if (response["reachedMax"] === false) {
        this.start += this.limit;
      }
      try {
        response["parts"].forEach((element) => {
          this.items.push(this.generateItem(element));
        });
        this.loading = false;
      } catch (error) {
        console.log(this.reachedMax);
        if (this.reachedMax === true) {
          console.log("Reached Max");
        } else {
          console.log(error);
        }
      }
    },
    pullDataWithDelay() {
      return new Promise((resolve) =>
          setTimeout(() => resolve(this.fetchItems()), 100)
      );
    },
  },
};
</script>